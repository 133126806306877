import wx from 'weixin-jsapi'
import qs from 'qs'
// import {Toast} from 'mint-ui'
import axios from "axios";
import api from "@/dev/api.ts";

axios.defaults.headers.common["Content-Type"] ="application/json; charset=UTF-8";
// axios.defaults.headers.common["clientType"] = "H5";
// axios.defaults.withCredentials = false;
 
/**
 *分享
 * @param _this 当前域
 * @param shareTitle 标题
 * @param shareUrl 链接
 * @param shareImg 图片
 * @param shareDesc 描述
 */
export const  commonShare = (_this, shareTitle, shareUrl, shareImg, shareDesc) => {
    // let url = location.href.split('#')[0];
    let url = location.href;
    // let params = qs.stringify({
    //     shareUrl: url     //shareUrl是根据你的后台需要的参数名
		// });
		console.log("url: ",url)
		console.log("参数： ",shareTitle,shareUrl,shareImg,shareDesc)
		const formData = new FormData();
		formData.append("url",url)
		// formData.append('token',eyJhbGciOiJIUzI1NiJ9.eyJsb2dpblR5cGUiOjAsInVzZXIiOnsiaW1nIjoiXC9yZXNvdXJjZXNcL3RyYWRpbmdcL3VwbG9hZFwvanBnXC8wXzE2MDM2OTg0Nzc0MzAuanBnIiwieGxOYW1lIjoiIiwic2l6ZUxpc3QiOiJcIlwiIiwid2Rjb25jZXJuIjowLCJwcmFpc2VOdW0iOjAsImluZHVzdHJ5Ijoi5YW25LuWIiwiYmVDb21tZW50ZWROdW0iOjAsImludGVncmFsIjo3OCwiaW50cm8iOiLmma7pgJrnlKjmiLciLCJsb2dpbk5hbWUiOiIxODlQVTcyVCIsImlkIjoxMzM0MjI4LCJzdGF0ZSI6MSwid3hOYW1lIjoiIiwiZW1haWwiOiIxMzk3ODg4MjdAcXEuY29tIiwiY29tbWVudFRvTnVtIjowLCJhZGRyZXNzIjoi5rWL6K-V5Zyw5Z2AIiwiYXJ0aWNsZU51bSI6MCwibGV2ZWwiOjEsIndkQWNjZXB0TnVtIjowLCJpc0FkbWluIjowLCJmaWVsZCI6IiIsIm1vbmV5IjowLCJwaG9uZSI6IjEzMTgwNjYwMTI5IiwiY29uY2Vybk51bSI6MCwibmFtZSI6IjE4OVBVNzJUIiwid2RBbnN3ZXJOdW0iOjAsImlzRXhjZWxsZW50IjowfSwicmFuZG9tIjoiZGNlOGNjODUtYWIxNy00ZmEzLTg5MjQtYTM0NmVlMjlhMzkzIn0.vFfTuRHyh5ZdIAvl1pjuSHniRDazIjK2Iqy7GLx351w)
    axios.post(api.LoginURL.concat('/common/wxShare'),formData).then(data=>{
						console.log(data);
						console.log(data.data.data.timestamp)
            wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: 'wx401e795e61fdd396', // 必填，公众号的唯一标识
                timestamp: data.data.data.timestamp, // 必填，生成签名的时间戳
                nonceStr: data.data.data.noncestr, //注意：服务端签名和前端参数名大小写不一致
                signature: data.data.data.signature, // 必填，签名，见附录1
                jsApiList: ["onMenuShareTimeline", "onMenuShareAppMessage"] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
            });
            wx.ready(function () {
                wx.onMenuShareTimeline({
                title: shareTitle, // 分享标题
                link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: shareImg, // 分享图标
                success: function () {
                    // 用户确认分享后执行的回调函数
                    // Toast('分享成功！！！');
                },
                cancel: function () {
                    // 用户取消分享后执行的回调函数
                    // Toast('取消分享！！！');
                }
                });
                wx.onMenuShareAppMessage({
                title: shareTitle, // 分享标题
                desc: shareDesc, // 分享描述
                link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: shareImg, // 分享图标
                type: "", // 分享类型,music、video或link，不填默认为link
                dataUrl: "", // 如果type是music或video，则要提供数据链接，默认为空
                success: function () {
                    // 用户确认分享后执行的回调函数
                    // Toast('分享成功！！！');
                },
                cancel: function () {
                    // 用户取消分享后执行的回调函数
                    // Toast('取消分享！！！');
                }
                });
            });
        }).catch(err=>{
            console.log("自定义分享失败"+err);
        })
    
};