<template>
  <div class="lp-comment">
    <div class="comment">
      <div>全部{{ lengh }}条评论</div>
      <div class="comment-content">
        <!-- <div v-if="isLogin">
                <span ><span @click="login()"  class="login">登录</span>,参与讨论前请登录</span></div>  -->
        <textarea
          id="comment"
          v-model="content"
          placeholder="请输入评论"
          style="width:100%;height:100%;font-size:15px;padding:3px;resize: none"
        ></textarea>
      </div>
      <div class="operate-bar">
        <!-- <div class="sync-check"> 
                <input type="checkbox"/>
                <span>同步到新浪微博</span>
            </div> -->
        <div class="send-comment">
          <button @click="sendComment()">发表评论</button>
        </div>
      </div>
    </div>

    <div class="article-comment-container" data-item-id="-1" data-type-id="2">
      <div class="words">
        <ul class="comment-say" v-if="replyList">
          <!-- 显示一级评论 -->
          <li
            class="comment-say-li clr comment-level-1"
            v-for="onepcomment in replyList"
            :key="onepcomment.id"
          >
            <div class="csl-img">
              <a rel="nofollow">
                <img :src="onepcomment.authorImg" width="40" height="40" />
              </a>
            </div>
            <div class="csl-body">
              <div class="cont">
                <div class="row clr">
                  <a rel="nofollow" class="name replyName"
                    >{{ onepcomment.authorName }}
                  </a>
                  <span class="time">{{ onepcomment.time }}</span>
                </div>
                <div class="des">{{ onepcomment.content }}</div>
              </div>
              <div class="opBtn">
                <a
                  href="javascript:;"
                  @click="
                    writeBack(
                      onepcomment.commentator,
                      onepcomment.id,
                      onepcomment.id
                    )
                  "
                  class="respond-coin"
                  title="回复"
                >
                  <i />
                  <em>回复</em>
                </a>
                <span class="zan ">
                  <i />
                  <!-- <em class="z-count">									({{onepcomment.praiseNum}})								</em> -->
                </span>
              </div>
              <!-- 二次评论 -->
              <div class="respond-submit" v-if="pid == onepcomment.id">
                <div class="text">
                  <input
                    type="text"
                    v-model="content1"
                    style="width: 500px; padding-left: 50px; box-sizing: border-box;"
                  />
                  <div class="tip">
                    回复
                    <!-- <a>{{onepcomment.authorName}}</a> -->
                    ：
                  </div>
                </div>
                <div class="sub clr">
                  <!-- <span class="emotionc">表情</span> -->
                  <button @click="sendChildComment()">发表评论</button>
                  <div
                    class="qqFace"
                    style="position:absolute; display:none; z-index:10"
                  ></div>
                </div>
              </div>
              <ul class="csl-respond">
                <!-- 显示二级评论 -->
                <li
                  class="comment-respond-li clr comment-level-2"
                  v-for="onecomment in onepcomment.children"
                  :key="onecomment.id"
                >
                  <div class="crl-img">
                    <a rel="nofollow">
                      <!-- <img src="https://www.leiphone.com/uploads/new/avatar/05/21/89/61_avatar_pic_40_40.jpg?updateTime=1" width="45" height="45"> -->
                    </a>
                  </div>
                  <div class="crl-body">
                    <div class="cont">
                      <div class="um">
                        <!-- <img :src="onecomment.UserauthorImg" width="40" height="40"> -->
                        <a class="name replyName" target="_blank">{{
                          onecomment.UserauthorName
                        }}</a>
                        回复
                        <!-- <img :src="onecomment.authorImg" width="40" height="40">							 -->
                        <a class="name toReplyName" target="_blank">{{
                          onecomment.authorName
                        }}</a
                        >：
                      </div>
                      <div class="des">{{ onecomment.content }}</div>
                    </div>
                    <div class="opBtn">
                      <a
                        href="javascript:;"
                        @click.stop="
                          writeBacks(
                            onecomment.parentCommentId,
                            onecomment.id,
                            onepcomment.id
                          )
                        "
                        class="respond-coin"
                        title="回复"
                      >
                        <i />
                        <em>回复</em>
                      </a>
                      <!-- <span class="zan "> -->
                      <!-- <i/> -->
                      <!-- <em class="z-count">({{onecomment.praiseNum}})								</em> -->
                      <!-- </span> -->
                      <!-- 三次评论 -->
                      <div class="respond-submit" v-if="pic == onecomment.id">
                        <div class="text">
                          <input
                            v-model="content2"
                            type="text"
                            id="answer"
                            style="width: 500px; padding-left: 50px; box-sizing: border-box;"
                          />
                          <div class="tip">
                            回复
                            <!-- <a>{{onecomment.UserauthorName}}</a> -->
                            ：
                          </div>
                        </div>
                        <div class="sub clr">
                          <!-- <span class="emotionc">表情</span> -->
                          <button
                            @click="sendChildComments(onecomment.UserauthorId)"
                          >
                            发表评论
                          </button>
                          <div
                            class="qqFace"
                            style="position:absolute; display:none; z-index:10"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { formatDate } from "@/js/common/index.js";
import { DELETE, QUERY, UPDATE, INSERT } from "@/services/dao.js";
export default {
  data() {
    return {
      // isLogin:false,
      // isLogined:false,
      centersED: "", ///中间转换
      replyList: [],
      parentId: -1,
      pid: -1,
      pic: -1,
      level: 0,
      ancestorId: -1,
      UserID: "",
      content: "",
      content2: "",
      content1: "",
      lengh: "",
    };
  },
  props: {
    articleId: {
      type: Number,
      required: true,
    },
  },
  created() {
    this.user = this.$store.state.userinfo.id;
    this.init();
  },
  methods: {
    ///敏感词过滤
    async centerd(name) {
      await axios
        .post(
          "http://39.100.48.36:3016/getData",
          {
            content: name,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          // console.log("敏感词过滤："+JSON.stringify(response.data.message.filterContent))
          this.centersED = response.data.message.filterContent;
          console.log("赋值成功：" + this.centersED);
        })
        .catch(function(error) {
          console.log("错误" + error);
        });
    },
    //首次评论文章
    async sendComment() {
      //如果勾选了同步到新浪 判断是否是登录了新浪账号
      if (
        this.user < 0 ||
        this.user == "" ||
        this.user == null ||
        this.user == "undefined"
      ) {
        //alert("请您先登录！");
        this.$message({
          message: '请您先登录！',
          type: 'warning'
        });
      } else if (this.content == "") {
        this.$message({
          message: "你输入的信息为空，不能发布",
          type: "warning",
          duration: "1000",
        });
      } else {
        await this.centerd(this.content); //敏感词
        this.content = this.centersED; //赋值
        //执行评论发表
        // if(this.isLogin){
        // let content = "";
        // 文章ID
        let articleId = this.articleId;
        // this.content = document.getElementById("comment").value;
        //添加第一次评论
        let info = await INSERT(
          "POST",
          "",
          "   insert_Comment(objects: {whole: " +
            this.articleId +
            ', type: 3, time: "' +
            new Date() +
            '", parentCommentId: 0, isShield: 0,  content: "' +
            this.content +
            '", commentator: ' +
            this.user +
            ", ancestorCommentId: 0}) { affected_rows  }"
        );
        if (info.data.insert_Comment.affected_rows == 1) {
          //
          //查询评论数
          // let data = await QUERY(
          //   "post",
          //   "",
          //   "  Article(where: {id: {_eq: " +
          //     this.articleId +
          //     "}}) {   commentNum      }"
          // );
          //alert( data.data.Article[0].commentNum)
          //评论数+1
          // data.data.Article[0].commentNum += 1;
          //修改文章表中的评论数
          // let datdeds = await UPDATE(
          //   "post",
          //   "",
          //   "  update_TtArticle(where: {id: {_eq: " +
          //     this.articleId +
          //     "}}, _set: {commentNum: " +
          //     data.data.Article[0].commentNum +
          //     "}) { affected_rows }"
          // );
          // if (datdeds.data.update_TtArticle.affected_rows > 0) {
          this.$message({
            message: "评论成功！",
            type: "success",
          });
          this.content = "";
          this.init();
          // }
        } else {
          this.$message({
            message: "评论失败！",
            type: "warning",
          });
        }
        // }
      }
    },
    //    二次评论
    async sendChildComment() {
      // if(this.isLogin){
      if (
        this.user < 0 ||
        this.user == "" ||
        this.user == null ||
        this.user == "undefined"
      ) {
        //alert("请您先登录！");
        this.$message({
          message: '请您先登录！',
          type: 'warning'
        });
      } else if (this.content1 == "") {
        this.$message({
          message: "你输入的信息为空，不能发布",
          type: "warning",
          duration: "1000",
        });
      } else {
        await this.centerd(this.content1); //敏感词
        this.content1 = this.centersED; //赋值
        let articleId = this.articleId;
        let parentId = this.articleId; //获取被评论的作者ID
        // let content = document.getElementById("answer").value;
        //添加第二次评论
        let info = await INSERT(
          "POST",
          "",
          "   insert_Comment(objects: {whole: " +
            this.articleId +
            ', type: 3, time: "' +
            new Date() +
            '", parentCommentId: ' +
            this.parentId +
            ', isShield: 0,  content: "' +
            this.content1 +
            '", commentator: ' +
            this.user +
            ", ancestorCommentId: " +
            this.ancestorId +
            "}) { affected_rows  }"
        );
        if (info.data.insert_Comment.affected_rows > 0) {
          this.$message({
            message: "评论成功！",
            type: "success",
          });
          this.content1 = "";
          this.init();
        } else {
          this.$message({
            message: "评论失败！",
            type: "warning",
          });
        }
      }
    },
    //    三次评论
    async sendChildComments(UserauthorId) {
      // if(this.isLogin){
      if (
        this.user < 0 ||
        this.user == "" ||
        this.user == null ||
        this.user == "undefined"
      ) {
        //alert("请您先登录！");
        this.$message({
          message: '请您先登录！',
          type: 'warning'
        });
      } else if (this.content2 == "") {
        this.$message({
          message: "你输入的信息为空，不能发布",
          type: "warning",
          duration: "1000",
        });
      } else {
        await this.centerd(this.content2); //敏感词
        this.content2 = this.centersED; //赋值
        let articleId = this.articleId;
        let parentId = this.articleId;
        // let content = document.getElementById("answer").value;
        //添加第二次评论
        let info = await INSERT(
          "POST",
          "",
          "   insert_Comment(objects: {whole: " +
            this.articleId +
            ', type: 3, time: "' +
            new Date() +
            '", parentCommentId: ' +
            this.parentId +
            ', isShield: 0,  content: "' +
            this.content2 +
            '", commentator: ' +
            this.user +
            ", ancestorCommentId: " +
            this.ancestorId +
            "}) { affected_rows  }"
        );
        if (info.data.insert_Comment.affected_rows > 0) {
          this.$message({
            message: "评论成功！",
            type: "success",
          });
          this.content2 = "";
          this.init();
        } else {
          this.$message({
            message: "评论失败！",
            type: "warning",
          });
        }
      }
    },
    login() {
      //    if(!this.isLogin){//如果没有登录 跳转到登录页面
      //        this.isLogin=true;
      //    this.$router.push({path:'/articleDetail', query: {
      //          id: this.articleId
      //    }});
      //    }
      //    this.init();
    },
    //显示评论
    async init() {
      //显示一级评论
      this.replyList.length = 0;
      //根据文章ID，知识头条的一级评论
      let info = await QUERY(
        "post",
        "",
        "   ZYOneComment(where: {whole: {_eq: " +
          this.articleId +
          "},type: {_eq: " +
          3 +
          "}}) {   ancestorCommentId    articleImg  articlename    whole   time   parentCommentId   isShield   id   content    commentator   authorName authorImg   authorId    }"
      );
      this.lengh = info.data.ZYOneComment.length;
      for (let i = 0; i < info.data.ZYOneComment.length; i++) {
        info.data.ZYOneComment[i].time = formatDate(
          Date.parse(info.data.ZYOneComment[i].time)
        );
        info.data.ZYOneComment[i].authorImg = this.api.LoginURL.concat(
          info.data.ZYOneComment[i].authorImg
        );
        let a = info.data.ZYOneComment[i];
        //显示二级评论
        let info_c = await QUERY(
          "post",
          "",
          "   ZYTwoComment(where: {whole: {_eq: " +
            this.articleId +
            "}, ancestorCommentId: {_eq: " +
            a.id +
            "},type: {_eq: " +
            3 +
            "}}) {  type    time   parentCommentId   isShield  id   content   commentator  authorName   authorImg   authorId      articlename   articleImg  ancestorCommentId  UserauthorImg   UserauthorId   UserauthorName }"
        );
        info_c.data.ZYTwoComment.time = formatDate(
          Date.parse(info_c.data.ZYTwoComment.time)
        );

        info_c.data.ZYTwoComment.authorImg = this.api.LoginURL.concat(
          info_c.data.ZYTwoComment.authorImg
        );
        a.children = info_c.data.ZYTwoComment;
        this.replyList.push(a);
      }
    },
    async writeBack(parentId, pid, ancestorCommentId) {
      this.pid = pid;
      this.pic = -1;
      this.parentId = parentId;
      this.ancestorId = ancestorCommentId;
      //alert(this.pid)
    },
    async writeBacks(parentId, pic, ancestorCommentId) {
      this.pic = pic;
      this.pid = -1;
      this.parentId = parentId;
      this.ancestorId = ancestorCommentId;
      //alert(this.pic)
    },
  },
};
</script>

<style scoped>
.lp-comment .comment {
  height: 200px;
}
.el-main .comment {
  margin-top: 10px;
  background-color: #ffffff;
  padding: 10px;
  margin-left: 0;
  border-bottom: 1px solid #e5e5e5;
}
.lp-comment .comment .comment-content {
  height: 100px;
  margin-top: 12px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(210, 210, 210, 1);
}
.lp-comment .comment .comment-content span {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  height: 100%;
  line-height: 66px;
  top: 23px;
  display: inline-block;
}
.lp-comment .comment .comment-content .login {
  color: rgba(253, 110, 5, 1);
  margin-left: 20px;
}
.lp-comment .comment .comment-content .login span {
  cursor: pointer;
}
/* 文章点评操作栏 */
.lp-comment .comment .operate-bar {
  position: relative;
  margin-top: 14px;
}
.lp-comment .comment .operate-bar .sync-check {
  position: absolute;
  left: 0px;
}
.lp-comment .comment .operate-bar .send-comment {
  position: absolute;
  cursor: pointer;
  right: 0px;
}
.lp-comment .comment .operate-bar .send-comment button {
  width: 84px;
  height: 28px;
  background: rgba(253, 110, 5, 1);
  border: 0px solid rgba(253, 110, 5, 1);
  border-radius: 3px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: rgba(250, 251, 253, 1);
  cursor: pointer;
}

.lp-comment .words {
  padding-bottom: 0;
}
.lp-comment .words h3 {
  font-size: 14px;
  color: #999999;
  height: 35px;
  line-height: 35px;
  border-bottom: solid 1px #e8e8e8;
  position: relative;
  margin-top: 4px;
  font-weight: normal;
}
.lp-comment .words h3 strong {
  font-size: 16px;
  color: #738a9a;
  position: absolute;
  bottom: -1px;
  left: 0;
}
ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.lp-comment .words .comment-say-li {
  padding: 20px 0 8px;
  border-bottom: solid 1px #e6e6e6;
}
.lp-comment .words li,
.lp-comment .jcWords .comment-say-li {
  position: relative;
}
.comment-say {
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  /* padding-right: 50px; */
  padding: 0 50px 0 20px;
}
.clr {
  zoom: 1;
}
li {
  list-style: none;
}
li {
  display: list-item;
  text-align: -webkit-match-parent;
}
.clr:after {
  clear: both;
  content: "\0020";
  display: block;
  visibility: hidden;
  height: 0;
}
.lp-comment .words .comment-say .csl-img {
  width: 45px;
  float: left;
}
a {
  text-decoration: none;
  color: #333;
  border: 0;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
}
a:-webkit-any-link {
  cursor: pointer;
}
.lp-comment .words .comment-say .csl-img img {
  border-radius: 50%;
  width: 45px;
  height: 45px;
  vertical-align: top;
}
img {
  border: 0;
}
a {
  color: #333;
  font-size: 100%;
}
.lp-comment .words .comment-say .csl-body {
  position: relative;
}
.lp-comment .words .comment-say .csl-body {
  padding-left: 60px;
}

.lp-comment .words .comment-say .csl-body .cont a.name {
  display: inline-block;
}
.lp-comment .words .comment-say .csl-body .cont a.name {
  font-size: 14px;
  color: #31424e;
}
a {
  text-decoration: none;
  color: #333;
  border: 0;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
}

.lp-comment .words .comment-say .csl-body .cont .row .time {
  font-size: 14px;
  color: #9baab6;
  padding-left: 10px;
}
.lp-comment .words .comment-say .csl-body .cont .des,
.lp-comment .jcWords .comment-say .csl-body .cont .des {
  font-size: 13px;
  color: #5e6b73;
  word-break: break-all;
  line-height: 24px;
}
.lp-comment .comment-say .csl-body .opBtn {
  font-size: 12px;
  color: #cccccc;
  height: 14px;
  line-height: 14px;
  font-style: inherit !important;
}
.lp-comment .comment-say .csl-body .opBtn a.respond-coin i {
  display: inline-block;
  /* background: url(../../images/common/yp_spirit.png) no-repeat -130px -270px; */
  width: 14px;
  height: 13px;
}
.lp-comment .comment-say .csl-body .opBtn a.respond-coin em {
  color: #aaaaaa;
  font-size: 13px;
  position: relative;
  top: -2px;
  margin-left: 5px;
}
.lp-comment .comment-say .csl-body .opBtn .zan {
  display: inline-block;
  float: right;
  position: relative;
  top: -2px;
}
.lp-comment .comment-say .csl-body .zan i {
  display: inline-block;
  cursor: pointer;
  /* background: url(../../images/common/yp_spirit.png) no-repeat -130px -245px; */
  width: 13px;
  height: 13px;
}
i {
  font-style: normal;
}
.lp-comment .comment-say .csl-body .zan em {
  position: relative;
  top: -1px;
  font-size: 14px;
}
.lp-comment .comment-say .csl-respond {
  padding: 10px 0 0px;
}
.lp-comment .words .comment-say .comment-respond-li {
  padding: 20px 0;
}
.lp-comment .words li,
.lp-comment .jcWords .comment-say-li {
  position: relative;
}
.lp-comment .words .comment-say .crl-img {
  width: 45px;
  float: left;
}
.lp-comment .words .comment-say .crl-body {
  padding-left: 35px;
}
.lp-comment .words .comment-say .crl-body .cont {
  color: #666666;
  font-size: 14px;
  padding-bottom: 10px;
}
.lp-comment .words .comment-say .crl-body .um {
  float: left;
}
.lp-comment .comment-say .csl-body .opBtn a.respond-coin {
  float: right;
}
.lp-comment .comment-say .csl-body .opBtn span {
  padding-right: 22px;
  float: left;
}
.lp-comment .words .comment-say .csl-body .text {
  position: relative;
}
.lp-comment .words .comment-say .csl-body .text input {
  border-radius: 2px;
  padding-right: 10px;
  height: 37px;
  line-height: 14px;
  font-size: 14px;
  line-height: 37px\9;
  color: #5a5a5a;
  border: solid 1px #eeeeee;
  background: #f7f7f7;
}
.lp-comment .words .comment-say .csl-body .text .tip {
  font-size: 14px;
  color: #999999;
  position: absolute;
  top: 11px;
  left: 10px;
}
.lp-comment .words .comment-say .csl-body .text .tip a {
  padding: 0 5px;
}
.lp-comment .words .comment-say .csl-body .sub {
  padding-top: 10px;
}
.lp-comment span.emotionc {
  font-size: 12px;
  color: #666;
  width: 42px;
  height: 20px;
  /* background: url(../../images/common/lph-comment-face.gif) no-repeat 2px 2px; */
  padding-left: 21px;
  cursor: pointer;
  margin-right: 10px;
}
.lp-comment span.emotionc {
  /* background: url(../../images/face.png) 0 0 no-repeat; */
  width: 21px;
  height: 19px;
  display: inline-block;
  vertical-align: middle;
  text-indent: -999em;
}
button {
  -webkit-appearance: button;
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: buttontext;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  cursor: default;
  background-color: buttonface;
  box-sizing: border-box;
  margin: 0em;
  font: 400 13.3333px Arial;
  padding: 1px 6px;
  border-width: 2px;
  border-style: outset;
  border-color: buttonface;
  border-image: initial;
}
.lp-comment .words .comment-say .csl-body .sub button {
  float: right;
  font-family: Microsoft Yahei;
  width: 100px;
  height: 40px;
  line-height: 40px;
  right: 0;
  top: 48px;
  text-align: center;
  background: #ea7754;
  color: #fff;
  font-size: 14px;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
}
.lp-comment .qqFace {
  margin-top: 4px;
  background: #fff;
  padding: 2px;
  border: 1px #dfe6f6 solid;
}
.clr:after {
  clear: both;
  content: "\0020";
  display: block;
  visibility: hidden;
  height: 0;
}
em {
  font-style: normal;
}
</style>
