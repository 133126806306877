<template>
  <div class="detail">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: 'Resource_transactions' }">资源交易</el-breadcrumb-item>
      <el-breadcrumb-item>{{ classification }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class>
      <el-container>
        <el-main>
          <div class="content">
            <div class="introduction">
              <div class="introduction_left">
                <div class="bq">
                  <span v-for="item in list" :key="item">{{ item }}&emsp;</span>
                </div>
                <div class="olof">
                  <el-image :src="this.url"></el-image>

                  <span>{{ this.name }}</span>
                </div>
                <div class="niko">
                  <span>资源等级{{ this.level }}</span>|
                  <span>{{ this.read }}阅读</span>
                  <span>{{ this.download }}下载</span>|
                </div>
                <div class="niko">
                  <span>{{ this.Time }}上传</span>|
                  <span>{{ this.pageall }}页</span>|
                  <span>{{ this.size }}</span>
                </div>
                <!-- <div class="niko_1">
                  <img class="score" src="@/assets/icon/Collection1.png" />收藏
                  <img class="score" src="@/assets/icon/share2.png" />分享
                </div>-->
              </div>
              <div class="introduction_right">
                <img :src="this.img" class="image_c">
                <!-- <el-image :src="this.img"></el-image> -->
                <!-- <span>{{img}}</span> -->
                <!-- <el-button type="primary" size="small" @click="Download"
                  >{{ this.Price }}下载文档</el-button
                >-->
              </div>
            </div>
            <!-- <div class="introduction_center">
              <div class="introduction_center_left">
                <el-image :src="this.img"></el-image>
                <span>{{ this.name }}</span>
              </div>
              <span class="introduction_center_right">1/{{ this.page }}</span>
            </div>
            <div class="text">
              <p>{{ this.content }}</p>
            </div>-->
          </div>
          <div class="locking">
            <div class="pdfy" v-loading="loading" v-if="pdshow">
              <div style="marginTop: 10px; color: #409EFF">{{ pageNum }} / {{ pageTotalNum }}</div>
              <pdf
                :page="pageNum"
                :src="pdfurl"
                @progress="loadedRatio = $event"
                @num-pages="pageTotalNum = $event"
              ></pdf>
              <el-button-group style="width:100%">
                <el-button
                  icon="el-icon-arrow-left"
                  size="mini"
                  @click="prePage"
                  style="width:50%"
                >上一页</el-button>
                <el-button size="mini" @click="nextPage" style="width:50%">
                  下一页
                  <i class="el-icon-arrow-right el-icon--right"></i>
                </el-button>
              </el-button-group>
            </div>
            <div class="locking_bottom">
              <img
                class="score"
                src="@/assets/icon/Fabulous.png"
                v-show="showPraise"
                @click="onPraise"
              />
              <img
                class="score"
                src="@/assets/icon/Fabuloused1.png"
                v-show="Praise"
                @click="deletePraise"
              />
              {{ this.Collection }}
              <img
                class="score"
                src="@/assets/icon/comment1.png"
              />
              {{
              this.comment
              }}
              <img
                class="score"
                src="@/assets/icon/Collection1.png"
                v-show="showKoleksi"
                @click="onKoleksi"
              />
              <img
                class="score"
                src="@/assets/img/icon-collected.png"
                v-show="Koleksi"
                @click="deleteKoleksi"
              />
收藏
              <el-popover placement="bottom" width="400" trigger="click">
                <div class="share">
                  <ul>
                    <!-- <li @click="share('WX')"><span>微信</span></li> -->
                    <!-- 这个才是 -->
                    <li @click="dealShare($event, 'weixin')">
                      <span>
                        <img class="scoreS" src="@/assets/icon/20190506 WX.png" />微信
                      </span>
                    </li>

                    <li @click="share('WB')">
                      <span>
                        <img class="scoreS" src="@/assets/icon/WB0000.png" />微博
                      </span>
                    </li>
                    <li @click="share('qzone')">
                      <span>
                        <img class="scoreS" src="@/assets/icon/20190506 QQ.png" />QQ空间
                      </span>
                    </li>
                  </ul>
                </div>
                <span slot="reference" style="  cursor: pointer;">
                  <img class="score" src="@/assets/icon/share2.png" />分享
                </span>
                <div id="qrcode" ref="qrcode"></div>
              </el-popover>
              <div class="inline-blc">
                <div v-show="show_nanativePrice">
                  <font class="show-discount-price">原价: ￥{{ nativePrice }}</font>
                </div>
                <el-button
                  type="primary"
                  size="small"
                  @click="Down"
                  class="button"
                >￥{{ this.Price }}下载文档</el-button>
              </div>
            </div>
          </div>
          <div>
            <V-Comment :articleId="this.id"></V-Comment>
          </div>
        </el-main>
        <el-aside width="2.205882rem">
          <div class="user" @click="repot()">
            <el-image :src="nmsl"></el-image>
            <br />
            <div class="um">{{ this.cnm }}</div>
          </div>
          <!-- 是否订阅 登录用户为当前作者不显示-->
          <div class="information" v-if="isSelf">
            <!-- <span class="ppv">资源<br />{{ this.zy }}</span> -->
            <div class="showSubs">
              <div v-show="showSubs">
                <li>
                  <span class="modifyFloat">订阅方式:</span>
                  <font>{{ subsType }}</font>
                </li>
                <li class="pad15-t">
                  <span class="modifyFloat">订阅有效期:</span>
                  <font>{{ subsExpires }}</font>
                </li>
              </div>
              <div v-show="!showSubs" class>
                <li class="noSubs" id="subs-tip">未订阅</li>
              </div>
            </div>
          </div>

          <!-- <div class="relevant">
            <div class="rel_n">
              <span>相关文档</span>
            </div>
            <ul class="aside_t_b">
              <li
                class="plcont"
                v-for="(item, index) in Collectionlist.slice(0, 5)"
                :key="index"
                @click="change(item)"
              >
                <div class="index">{{ index + 1 }}</div>
                <div class="ledt">
                  <div class="title_ct">{{ item.name }}</div>
                  <br />
                  <div style="margin-top:5px">
                    资源等级{{ item.level }}
                    <span class="down">{{ item.download }}下载</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>-->

          <div class="relevant">
            <div class="rel_n">
              <span>推荐标签</span>
            </div>
            <ul class="aside_t_b lableTab">
              <li
                @click="Jump(item.name.split('$text$')[1])"
                class="lableLi"
                v-for="(item, index) of labelList"
                :key="index"
              >
                {{item.name.split('$text$')[1]}}
                <!-- @click="change(item)" -->
                <!-- <div class="index">{{ index + 1 }}</div>
                <div class="ledt">
                  <div class="title_ct">{{ item.name }}</div>
                  <br />
                  <div style="margin-top:5px">
                    资源等级{{ item.level }}
                    <span class="down">{{ item.download }}下载</span>
                  </div>
                </div>-->
              </li>
            </ul>
          </div>

          <div class="relevant">
            <div class="rel_n">
              <span>问题文档</span>
              <div class="btn_dy" @click="tapDyZy(IssueWoordList)">订阅</div>
            </div>
            <ul class="aside_t_b">
              <li
                class="plcont"
                v-for="(item, index) in IssueWoordList"
                :key="index"
                @click="change(item)"
              >
                <div class="index">{{ index + 1 }}</div>
                <div class="ledt">
                  <div class="title_ct">{{ item.name }}</div>
                  <br />
                  <div style="margin-top:5px">
                    资源等级{{ item.level }}
                    <span class="down">{{ item.download }}下载</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </el-aside>
      </el-container>
    </div>
  </div>
</template>

<script>
import { INSERT, QUERY, DELETE, QUERYED } from "@/services/dao.js";
import { showTimeFormat, showTimeFormat2, Solution } from "@/js/common";
import Comment from "@/components/Resource_expert/comment/index.vue";
import { integralAddOne } from "@/js/common";
import { updateread } from "@/js/common/Resoures";
import pdf from "vue-pdf";
import axios from "axios";
import QRCode from "qrcodejs2"; // 将网址信息生成为二维码的插件
import wx from "weixin-js-sdk"; // 微信sdk依赖
import { commonShare } from "@/js/common/weiXinShareSdk.js";
export default {
  components: {
    "V-Comment": Comment,
    pdf
  },
  data() {
    return {
      id: 190,
      url: "@/assets/icon/WORD@2x.png",
      cnm: "11111",
      userid: "",
      score: "",
      name: "",
      read: "",
      download: 0,
      loading: true,
      pdshow: true,
      zy: 0,
      Time: "",
      page: "",
      pageall: "",
      size: "",
      img:
        "https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=2594104736,4220653550&fm=26&gp=0.jpg",
      Price: "",
      content:
        "这种用整数表示的方法称为分类或字典编码表示法。不同值得数组称为分类、字典或数据级。本书中，我们使用分类的说法。表示分类的整数值称为分类编码或简单地称为编码。分类表示可以在进行分析时大大的提高性能。你也可以在保持编码不变的情况下，对分类进行转换。一些相对简单的转变例子包括重命名分类。加入一个新的分类，不改变已经存在的分类的顺序或位置。pandas的分类类型pandas有一个特殊的分类类型，用于保存使用整数分类表示法的数据。看一个之前的Series例子：",
      list: ["数据分析", "实用科学", "计算机", "编译器"],
      Collection: "0",
      comment: "0",
      circleurl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      sizeList: ["large", "medium", "small"],
      user: "aaaaaaaa",
      value1: 3.5,
      colors: ["#99A9BF", "#F7BA2A", "#FF9900"],
      Collectionlist: [],
      IssueWoordList: [],
      showPraise: true,
      Praise: false,
      showKoleksi: true,
      Koleksi: false,
      nmsl: "",
      classification: "",
      pdfurl: "",
      pageNum: 1,
      pageTotalNum: 1, // 总页数
      loadedRatio: 0, // 当前页面的加载进度，范围是0-1 ，等于1的时候代表当前页已经完全加载完成了
      isSelf: false, // 当前登录用户是否为作者本人
      showSubs: false, // 是否订阅当前作者显隐
      subsExpires: "", // 订阅过期
      subsType: "", // 订阅类型
      level: 0, // 解决控制台 Property or method "level" is not defined on the instance
      nativePrice: 0, // 订阅用户后 展示的原价
      show_nanativePrice: false, // 是否显示 订阅后原价
      labelList: [] //推荐标签
    };
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.query.id != from.query.id) {
        // this.init(); //重新加载数据
        this.id = Number(this.$route.query.id);
        this.info();
        this.pdf();

        this.getIssueWoord();
      }
    }
  },
  created() {
    this.id = Number(this.$route.query.id);
    this.info();
    // this.init();
    this.pdf();

    this.getIssueWoord();
  },
  methods: {
    Jump(name) {
      this.$router.push({
        path: "/Resource_theme",
        query: {
          name: name,
          type:0
        }
      });
    },
    //订阅资源
    async tapDyZy(list) {
      let userId = this.$store.state.userinfo.id;
      if (!userId) {
        this.$message({
          message: "请您先登录！",
          type: "warning",
          duration: "1000"
        });
        return;
      }
      for (let item of list) {
        let zyId = item.id;
        let res = await QUERYED(
          "post",
          "",
          `UserAttentionZy(where:{zyId:{_eq:${zyId}},userId:{_eq:${userId}},type:{_eq:"2"}}) {id}`
        );
        if (res.success && res.data.UserAttentionZy.length <= 0) {
          let res = await INSERT(
            "post",
            "",
            `insert_UserAttentionZy(objects:{zyId:${zyId},userId:${userId},type:"2"}){affected_rows}`
          );
        }
      }
      this.$message({
        message: "订阅成功",
        type: "success",
        duration: "1000"
      });
    },

    //获取推荐标签
    async getLabelList() {
      let res = await QUERYED(
        "post",
        "",
        'ZYDocument(where:{classification:{_eq:"' +
          this.classification +
          '"}}){keyword}'
      );
      if (res.success) {
        let obj = {};
        let arr = [];
        for (let item of res.data.ZYDocument) {
          if (item.keyword) {
            for (let text of JSON.parse(item.keyword)) {
              text = "$text$" + text;
              obj[text] = obj[text] ? obj[text] + 1 : 1;
            }
          }
        }
        for (let name in obj) {
          arr.push({ name, num: obj[name] });
        }
        arr.sort((a, b) => {
          return b.num - a.num;
        });
        this.labelList = arr.splice(0, 10);
      }
    },

    // async init() {
    //   let res = await QUERY(
    //     "post",
    //     "",
    //     "ZYDocument{    Price read  Time   collection  download    id   img  name  score  user level}"
    //   );
    //   this.Collectionlist = res.data.ZYDocument;
    //   for (var i = 0; i < this.Collectionlist.length; i++) {
    //     this.Collectionlist[i].img = this.api.LoginURL.concat(
    //       this.Collectionlist[i].img
    //     );
    //   }
    //   // 未登录用户 不能初始化该请求
    //   if (!this.$store.state.userinfo.id) {
    //     let res1 = await QUERY(
    //       "post",
    //       "",
    //       'ZYDocument(where: {user: {_eq: "' +
    //         this.user +
    //         '"},id:{_eq:' +
    //         this.$route.query.id +
    //         "}}) { }"
    //     );
    //     if (JSON.stringify(res1.data) != "{}") {
    //       this.Price = res1.data.ZYDocument[0].Price;
    //     } else {
    //       this.Price = 0; // 该篇资源为本用户上传
    //     }
    //   }
    // },

    //获取问题文档
    async getIssueWoord() {
      let res = await QUERYED(
        "post",
        "",
        'ZYDocument(limit:10,offset:0,where:{_or:[{name:{_like:"%为什么%"}},{name:{_like:"%?%"}}]}){Price read  Time collection download id img name  score  user level}'
      );
      if (res.success) {
        for (let item of res.data.ZYDocument) {
          item.img = this.api.LoginURL.concat(item.img);
        }
        this.IssueWoordList = res.data.ZYDocument;
      }
    },

    async info() {
      let articleInfo = await QUERYED(
        "POST",
        "",
        'ZYDocument(where: {id: {_eq: "' +
          this.id +
          '"}}) {   level Price  file  Time pageall  collection  download format classification  read keyword id   img  name  score  user  page Febulous}'
      );
      console.log(articleInfo, 789);
      let list = articleInfo.data.ZYDocument[0];
      this.classification = list.classification;
      
      this.getLabelList();
      this.img = this.api.LoginURL.concat(list.img);
      this.name = list.name;
      this.score = list.score;
      this.level = list.level;
      // this.Price = list.Price; // 由于订阅后需要 判断赋值 移至下方判断赋值
      this.read = list.read;
      this.list = eval(list.keyword);
      this.download = list.download;
      this.Time = showTimeFormat(Date.parse(list.Time));
      this.page = list.page;
      this.pageall = list.pageall;
      this.user = list.user;
      this.file = list.file;
      this.Febulous = list.Febulous;
      if (list.format == "mp3") {
        this.pdshow = false;
        this.url = require("@/assets/img/MP3.png");
        this.img = require("@/assets/img/MP3.png");
      } else if (list.format == "mp4") {
        this.pdshow = false;
        this.url = require("@/assets/img/MP4.png");
        this.img = require("@/assets/img/MP4.png");
      } else if (list.format == "jpg" || list.format == "jpeg") {
        this.pdshow = false;
        this.url = this.api.LoginURL.concat(list.file);
        this.img = this.api.LoginURL.concat(list.file);
      } else if (list.format == "png") {
        this.pdshow = false;
        this.url = this.api.LoginURL.concat(list.file);
        this.img = this.api.LoginURL.concat(list.file);
      } else {
        this.pdshow = true;
        switch (list.format) {
          case "docx":
            this.url = require("@/assets/icon/WORD@2x.png");
            break;
          case "txt":
            this.url = require("@/assets/icon/txt(1)@2x.png");
            break;
          case "pdf":
            this.url = require("@/assets/icon/PDF48@2x.png");
            break;
          case "excel":
            this.url = require("@/assets/icon/excel(2) 拷贝@2x.png");
            break;
          case "ppt":
            this.url = require("@/assets/icon/ppt@2x.png");
            break;
          default:
            this.url = require("@/assets/icon/？@2x.png");
        }
      }
      let infou = await QUERY(
        "post",
        "",
        "PCUsers(where: {id: {_eq: " +
          list.user +
          "}}) {   id    img      name     }"
      );
      axios({
        method: "post",
        url: this.api.LoginURL.concat("/resources/trading/interceptionPDF"),
        params: {
          filePath: this.file,
          pageNum: this.page
        }
      })
        .then(response => {
          this.pdfurl = this.api.LoginURL.concat(response.data.data.splitPDF);
          this.loading = false;
        })
        .catch(function(error) {});

      this.userid = infou.data.PCUsers[0].id;
      this.nmsl = this.api.LoginURL.concat(infou.data.PCUsers[0].img);
      this.cnm = infou.data.PCUsers[0].name;
      let PraiseInfo = await QUERY(
        "POST",
        "",
        'ZYPraise(where: {praiseMan: {_eq: "' +
          this.$route.query.id +
          '"}}) {   id}'
      );
      this.Collection = PraiseInfo.data.ZYPraise.length;
      //  let PraiseInfo = await QUERY(
      //   "POST",
      //   "",
      //   'ZYPraise(where: {user: {_eq: "' +
      //     this.$store.state.userinfo.id +
      //     '"},praiseMan: {_eq: "' +
      //     this.$route.query.id +
      //     '"}}) {   id}'
      // );
      // this.Collection = PraiseInfo.data.ZYPraise.length;
      let infocom = await QUERY(
        "post",
        "",
        "   ZYOneComment(where: {whole: {_eq: " +
          this.$route.query.id +
          "},type: {_eq: " +
          3 +
          "}}) {      id    }"
      );
      this.comment = infocom.data.ZYOneComment.length;
      // 未登录用户 不能初始化该请求
      if (
        !(
          this.$store.state.userinfo.id === "" ||
          this.$store.state.userinfo.id === undefined ||
          this.$store.state.userinfo.id == null
        )
      ) {
        let pInfo = await QUERY(
          "POST",
          "",
          'ZYPraise(where: {user: {_eq: "' +
            this.$store.state.userinfo.id +
            '"},praiseMan: {_eq: "' +
            this.$route.query.id +
            '"}}) {   id}'
        );
        if (pInfo.data.ZYPraise.length == "1") {
          this.showPraise = false;
          this.Praise = true;
        }

        let KoleksiInfo = await QUERY(
          "POST",
          "",
          'ZYKoleksi(where: {Pengumpul: {_eq: "' +
            this.$store.state.userinfo.id +
            '"},beKoleksi: {_eq: "' +
            this.$route.query.id +
            '"}}) {   id}'
        );
        if (KoleksiInfo.data.ZYKoleksi.length == "1") {
          this.showKoleksi = false;
          this.Koleksi = true;
        }
      } else {
        this.showPraise = true;
        this.Praise = false;
        this.showKoleksi = true;
        this.Koleksi = false;
      }
      // let KoleksiInfo = await QUERY(
      //   "POST",
      //   "",
      //   'ZYKoleksi(where: {Pengumpul: {_eq: "' +
      //     this.$store.state.userinfo.id +
      //     '"},beKoleksi: {_eq: "' +
      //     this.$route.query.id +
      //     '"}}) {   id}'
      // );
      // if (KoleksiInfo.data.ZYKoleksi.length == "1") {
      //   this.showKoleksi = false;
      //   this.Koleksi = true;
      // } else {
      //   this.showKoleksi = true;
      //   this.Koleksi = false;
      // }

      //
      // 初始化 是否订阅
      if (
        !(
          this.userid === "" ||
          this.userid === undefined ||
          this.userid == null
        ) &&
        !(
          this.$store.state.userinfo.id === "" ||
          this.$store.state.userinfo.id === undefined ||
          this.$store.state.userinfo.id == null
        ) &&
        this.$store.state.userinfo.id != this.userid
      ) {
        let resp = await QUERYED(
          "post",
          "",
          "SubscribeUserInfo(where:{subscribers_user_id:{_eq:" +
            this.$store.state.userinfo.id +
            "},subscribed_user_id:{_eq:" +
            this.userid +
            "}}) {id expires subscribe_type subscribe_state}"
        );
        if (resp.data.SubscribeUserInfo.length != 0) {
          // 当前登录用户已订阅该作者 通过 subscribe_state 判断是否过期
          // 同一作者订阅信息不止一条时
          let flag = true;
          for (let i = 0; i < resp.data.SubscribeUserInfo.length; i++) {
            if (resp.data.SubscribeUserInfo[i].subscribe_state == 0) {
              // 找到未过期订阅信息
              this.subsExpires = showTimeFormat2(
                resp.data.SubscribeUserInfo[i].expires
              );
              if (
                resp.data.SubscribeUserInfo[i].subscribe_type.search("一次性") >
                -1
              ) {
                // 一次性订阅不展示 折扣字样
                this.subsType = resp.data.SubscribeUserInfo[
                  i
                ].subscribe_type.split("+")[0];
                // 一次性付费显示 资源价格为 0
                this.Price = 0;
              } else {
                // 基本加折扣订阅
                this.subsType = resp.data.SubscribeUserInfo[i].subscribe_type;
                // 计算 折扣后价格
                let discount =
                  (resp.data.SubscribeUserInfo[i].subscribe_type
                    .split("+折扣 (")[1]
                    .split(")")[0] /
                    10) *
                  1.0;
                // list.Price = Math.round(list.Price * Number(discount),2)
                this.Price = Solution(list.Price, discount).toFixed(2);
              }

              flag = false;
              this.showSubs = true;
              this.show_nanativePrice = true;
              this.nativePrice = list.Price;
            }
          }
          if (flag) {
            //  所有订阅信息 已过期则给出提示
            /**
             * created 无法操作 dom
             */

            // let e = document.getElementsByClassName('noSubs');
            // e[0].innerText = "订阅已过期"
            // this.showSubs = false;
            this.Price = list.Price; // 订阅过但过期了或者取消订阅了，都要回复原价
            this.showSubs = false;
            this.show_nanativePrice = false;
          }
          this.isSelf = true;
        } else {
          // 未订阅 （已登录用户)
          // 则显示 资源原价
          this.Price = list.Price;
          this.showSubs = false;
          this.isSelf = true;
        }
      } else {
        this.isSelf = false; // 本人不显示是否订阅
      }
      if (
        this.$store.state.userinfo.id === "" ||
        this.$store.state.userinfo.id === undefined ||
        this.$store.state.userinfo.id == null
      ) {
        // 未登录显示原价
        this.Price = list.Price;
      }
    },
    pdf() {},

    //调用分享
    share(stype) {
      var ftit = "";
      var flink = "";
      var lk = "";
      //获取文章标题

      ftit = this.name;
      //获取网页中内容的第一张图片
      flink = "/mobile/img/local/invitation.png";
      if (typeof flink == "undefined") {
        flink = "";
      }
      //当内容中没有图片时，设置分享图片为网站logo
      if (flink == "") {
        lk =
          "http://g.search2.alicdn.com/img/i2/126351555/TB25JDIACtYBeNjSspaXXaOOFXa_!!0-saturn_solar.jpg_220x220.jpg";
      }

      //qq空间接口的传参
      // 功能待测试线上
      if (stype == "qzone") {
        let url_resource = window.location.href || document.location.href;
        if (url_resource.indexOf("localhost") > -1) {
          url_resource = window.location.href
            .split("://")[1]
            .replace("localhost:8080", "http://www.izhihui.net");
        }
        // window.open(
        // "https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url="+
        //    encodeURI(window.location.href)+encodeURIComponent(
        //   "&sharesource=qzone"+
        //   "&title="+
        //   ftit+
        //   "&summary="+
        //   "&site="+
        //   "&pics=")

        var p = {
          url: encodeURIComponent(url_resource),
          showcount: "1" /*是否显示分享总数,显示：'1'，不显示：'0' */,
          desc: "这篇文章不错,分享一下~~" /*默认分享理由(可选)*/,
          summary: "" /*分享摘要(可选)*/,
          title: this.name /*分享标题(可选)*/,
          site: "汽车氪" /*分享来源 如：腾讯网(可选)summary*/,
          pics: this.img /*分享图片的路径(可选)*/,
          style: "101",
          width: 199,
          height: 30
        };
        var s = [];
        for (var i in p) {
          s.push(i + "=" + encodeURIComponent(p[i] || ""));
        }
        var target_url =
          "http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?" +
          s.join("&");
        window.open(
          target_url,
          "qZone",
          "height=430, width=400,top=(window.screen.availHeight-30-iHeight)/2,left=(window.screen.availWidth-10-iWidth)/2"
        );
      }
      //新浪微博接口的传参
      if (stype == "WB") {
        window.open(
          "http://service.weibo.com/share/share.php?url=" +
            encodeURIComponent(window.location.href) +
            "&title=" +
            ftit +
            "&pic=" +
            lk +
            "&appkey=2706825840"
        );
      }
      //分享到微信
      if (stype == "WX") {
        // let e = document.getElementById("qrcode");
        // e.innerHTML = "";
        // 目前是生成二维码，用户可以通过扫码进入网站
        // const wx = require("weixin-js-sdk");
        // this.$nextTick(() => {
        //   let qrcode = new QRCode("qrcode", {
        //     width: 200, // 设置宽度，单位像素
        //     height: 200, // 设置高度，单位像素
        //     text: window.location.href, // 设置二维码内容或跳转地址
        //   });
        // });
        // commonShare(this,this.name,window.location.href,this.img,"这篇文章不错 分享一下~")
      }
      //qq好友接口的传参
      if (stype == "QQ") {
        // window.open(
        //   "http://connect.qq.com/widget/shareqq/index.html?url=" +
        //     window.location.href +
        //     "?sharesource=qzone&title=" +
        //     ftit +
        //     "&pics=" +
        //     lk +
        //     '&summary="hgjshdhjsdhsgd"'
        // );

        // 原生js for循环处理字符串
        let url_resource = window.location.href || document.location.href;
        if (url_resource.indexOf("localhost") > -1) {
          url_resource = window.location.href
            .split("://")[1]
            .replace("localhost:8080", "http://www.izhihui.net");
        }
        var p = {
          url: url_resource /*获取URL，可加上来自分享到QQ标识，方便统计*/,
          desc:
            "来自智荟网友的分享" /*分享理由(风格应模拟用户对话),支持多分享语随机展现（使用|分隔）*/,
          title: this.name /*分享标题(可选)*/,
          summary: "这篇文章不错,分享下下~~" /*分享描述(可选)*/,
          pics: this.img /*分享图片(可选)*/,
          flash: "" /*视频地址(可选)*/,
          //commonClient : true, /*客户端嵌入标志*/
          site: "QQ分享" /*分享来源 (可选) ，如：QQ分享*/
        };

        var s = [];
        for (var i in p) {
          s.push(i + "=" + encodeURIComponent(p[i] || ""));
        }
        var target_url =
          "http://connect.qq.com/widget/shareqq/iframe_index.html?" +
          s.join("&");
        window.open(
          target_url,
          "qq",
          "height=520, width=720,top=(window.screen.availHeight-30-iHeight)/2,left=(window.screen.availWidth-10-iWidth)/2"
        );
      }
      //生成二维码给微信扫描分享
      if (stype == "wechat") {
        window.open("inc/qrcode_img.php?url=" + window.location.href + "");
      }
    },
    //下载
    Down() {
      if (
        this.$store.state.userinfo.id<0||this.$store.state.userinfo.id==''
      ) {
        this.$message({
          message: "请您先登录！",
          type: "warning",
          duration: "1000"
        });
      } else {
        this.$router.push({
          path: "/Resource_download",
          query: {
            id: this.$route.query.id
          }
        });
      }
    },
    async onPraise() {
      if (
        this.$store.state.userinfo.id < 0 ||
        this.$store.state.userinfo.id == "" ||
        this.$store.state.userinfo.id == null ||
        this.$store.state.userinfo.id == "undefined"
      ) {
        this.$message({
          message: "请您先登录！",
          type: "warning",
          duration: "1000"
        });
      } else {
        this.createTime = new Date();
        let info = await INSERT(
          "POST",
          "",
          'insert_Praise(objects: {time: "' +
            this.createTime +
            '",praiseMan : "' +
            this.$route.query.id +
            '",user: "' +
            this.$store.state.userinfo.id +
            '",type: "2" }) {    affected_rows  returning{id}   }'
        );
        if (info.data.insert_Praise.affected_rows == "1") {
          this.showPraise = false;
          this.Praise = true;
          this.Collection = this.Collection * 1 + 1;
        }
      }
    },
    async deletePraise() {
      let info = await DELETE(
        "post",
        "",
        "delete_Praise(where: {praiseMan: {_eq: " +
          this.$route.query.id +
          "},user: {_eq: " +
          this.$store.state.userinfo.id +
          "}}) {     affected_rows   }"
      );
      if (info.data.delete_Praise.affected_rows == "1") {
        this.showPraise = true;
        this.Praise = false;
        this.Collection = this.Collection * 1 - 1;
      }
    },
    async onKoleksi() {
      if (
        this.$store.state.userinfo.id < 0 ||
        this.$store.state.userinfo.id == "" ||
        this.$store.state.userinfo.id == null ||
        this.$store.state.userinfo.id == "undefined"
      ) {
        this.$message({
          message: "请您先登录！",
          type: "warning",
          duration: "1000"
        });
      } else {
        this.createTime = new Date();
        let info = await INSERT(
          "POST",
          "",
          'insert_Koleksi(objects: {time: "' +
            this.createTime +
            '",beKoleksi : "' +
            this.$route.query.id +
            '",Pengumpul: "' +
            this.$store.state.userinfo.id +
            '",type: "2" }) {    affected_rows  returning{id}   }'
        );
        if (info.data.insert_Koleksi.affected_rows == "1") {
          this.showKoleksi = false;
          this.Koleksi = true;
        }
      }
    },
    async deleteKoleksi() {
      let info = await DELETE(
        "post",
        "",
        "delete_Koleksi(where: {beKoleksi: {_eq: " +
          this.$route.query.id +
          "},Pengumpul: {_eq: " +
          this.$store.state.userinfo.id +
          "}}) {     affected_rows   }"
      );
      if (info.data.delete_Koleksi.affected_rows == "1") {
        this.showKoleksi = true;
        this.Koleksi = false;
      }
    },
    change(item) {
      this.$router.push({ url: "/Resource_details", query: { id: item.id } });
      // this.id = item.id;
      // this.info();
      // updateread(item.id, item.read);
      // integralAddOne(this.$store.state.userinfo.id);
    },
    prePage() {
      let page = this.pageNum;
      page = page > 1 ? page - 1 : this.pageTotalNum;
      this.pageNum = page;
    },

    // 下一页
    nextPage() {
      let page = this.pageNum;
      page = page < this.pageTotalNum ? page + 1 : 1;
      this.pageNum = page;
    },
    repot(item) {
      this.$router.push({
        path: "/Reexpert",
        query: {
          id: this.userid
        }
      });
    },
    // 微信分享
    dealShare(event, type) {
      //设置分享内容
      bShare.addEntry({
        title: this.name,
        url: window.location.href,
        summary: "这篇文章不错 分享一下~",
        pic: this.img
      });

      if (type != "more") {
        //开始分享
        bShare.share(event, type, 0);
        return false;
      } else {
        bShare.more(event);
        return false;
      }
    }
  }
};
</script>
<style scoped>
/* public css */
.inline-blc {
  display: inline-block;
}

li {
  list-style: none;
}
.detail {
  padding: 30px 161px 30px 139px;
}
.el-main {
  background-color: #f7f7f7;
}
.el-aside {
  width: 235px;
  margin-top: 20px;
  margin-left: 0px;
}
.content {
  background-color: #ffffff;
}
.introduction {
  border: 1px solid #f7f7f7;

  /* padding: initial; */
  overflow: hidden;
  padding: 20px;
}
.introduction_left {
  width: 60%;
  float: left;
}
.bq span {
  border-radius: 30px;
  border: 0.007353rem solid #bfbfbf;
  background-color: #eeeeee;
  padding: 2px 10px 2px 10px;
}
.bq > span + span {
  margin-left: 5px;
}
.olof {
  margin-top: 20px;
  margin-left: 10px;
  width: 100%;
  overflow: hidden;
}
.olof .el-image {
  width: 24px;
  height: 30px;
  margin-right: 10px;
  float: left;
}
.ledt {
  overflow: hidden;
  /* margin-left: 10px; */
  padding-left: 20px;
}
.down {
  float: right;
  margin-right: 70px;
}
.olof span {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 28px;
  /* background-color: blanchedalmond; */
  width: 350px; /* 要显示文字的宽度 */
  float: left; /* 左对齐，不设置的话只在IE下好用 */
  /* overflow: hidden; 超出的部分隐藏起来 */
  /* white-space: nowrap; 不显示的地方用省略号...代替 */
  /* text-overflow: ellipsis; 支持 IE */
  /* -o-text-overflow: ellipsis; 支持 Opera */
}
.niko {
  margin-top: 20px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  /* color: rgba(247,96,62,1); */
  line-height: 30px;
}
.niko img {
  width: 15px;
}
.niko span {
  margin-right: 10px;
  margin-left: 10px;
}
.niko_1 {
  margin-top: 70px;
}
.niko_1 img {
  width: 13px;
  margin-left: 30px;
}
.introduction_right {
  float: right;
  width: 25%;
  margin-top: 30px;
}
.introduction_right .el-image,.image_c {
  height: 120px;
}
.introduction_right button {
  width: 160px;
  height: 34px;
  margin-top: 25px;
}
.introduction_center {
  overflow: hidden;
  height: 56px;
  line-height: 56px;
  background-color: #f7f7f7;
  width: 98%;
  margin-left: 1%;
}
.introduction_center_left {
  float: left;
  margin-left: 30px;
}
.introduction_center_left .el-image {
  width: 23px;
  float: left;
  margin-top: 13px;
}
.introduction_center_left span {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 28px;
  margin-left: 10px;
}
.introduction_center_right {
  float: right;
  margin-right: 50px;
}
.text {
  padding: 20px 40px 20px 40px;
}
.text p {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 24px;
}
.locking {
  text-align: center;
  overflow: hidden;
  background-color: #ffffff;
  padding-bottom: 20px;
}
.locking_btn span {
  margin-left: 10px;
  margin-right: 10px;
}
.locking .btn {
  color: #0084ff;
}
.locking img {
  cursor: pointer;
  width: 13px;
  margin-left: 30px;
}
.locking .button {
  margin-left: 30px;
  width: 160px;
  height: 34px;
}
.locking_bottom {
  margin-top: 15px;
  margin-left: 30%;
}
.user {
  background-color: #eeeeee;
  height: 212px;
  cursor: pointer;
}
.user::after {
  display: block;
  content: "";
  clear: both;
}
.user .el-image {
  float: left;
  width: 102px;
  height: 102px;
  margin: 58px 10px 0px 100px;
  border-radius: 9999px;
}
.user .um {
  width: 100%;
  float: left;
  text-align: center;
  margin-top: 10px;
}
.el-aside .el-image img {
  width: 82px;
  margin: 58px 10px 0px 36px;
  border-radius: 9999px;
}
.user span {
  line-height: 212px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}

.information {
  background-color: #ffffff;
  padding: 20px;
  text-align: center;
  /* padding-bottom: 60px; */
}
.information .ppv {
  float: left;
  width: 30%;
}
.relevant {
  overflow: hidden;
  background-color: #ffffff;
  margin-top: 10px;
  padding: 10px;
}
.plcont {
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  padding-bottom: 14px;
  padding-top: 14px;
  border-bottom: 1px solid #f7f7f7;
}
.plcont .el-image {
  width: 31px;
  float: left;
}
.plcont .index {
  float: left;
  padding: 10px;
  background-color: #f7f7f7;
  margin-right: 10px;
}
.rel_n span {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 24px;
}
.rel_n .n_right {
  float: right;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: rgba(118, 118, 118, 1);
  line-height: 24px;
}
.rel_n {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title_ct {
  width: 150px; /* 要显示文字的宽度 */
  float: left; /* 左对齐，不设置的话只在IE下好用 */
  overflow: hidden; /* 超出的部分隐藏起来 */
  white-space: nowrap; /* 不显示的地方用省略号...代替 */
  text-overflow: ellipsis; /* 支持 IE */
  -o-text-overflow: ellipsis; /* 支持 Opera */
}
.pdfy {
  width: 50%;
  margin-left: 25%;
}
.share ul li {
  /* width: 80px;
  float: left; */
  width: 50%;
  /* height:20px; */
  /* line-height: 20px; */
  /* width: 80px; */
  float: left;
  text-align: center;
  position: relative;
  top: -5px;
  /* border:1px solid red */
}
.share ul li span {
  cursor: pointer;
}
.showSubs {
  /* background: #eeeeee; */
  /* background: #a82e2e; */
  /* height: 100px; */
  padding: 15px;
  text-align: center;
}
.pad15-t {
  padding-top: 15px;
}
.modifyFloat {
  float: left;
  margin-left: 40px;
}
.show-discount-price {
  color: #eb5b3b;
  text-decoration: line-through;
}

/* elementUI reset */
.olof >>> .el-image__error {
  font-size: 12px;
}
.scoreS {
  width: 15px;
  position: relative;
  top: 4px;
  margin-right: 1px;
}
.btn_dy {
  width: 80px;
  height: 35px;
  background-color: rgb(14, 134, 246);
  border-radius: 5px;
  text-align: center;
  line-height: 35px;
  color: #ffffff;
  font-size: 16px;
}
.lableTab {
  display: flex;
  flex-wrap: wrap;
}
.lableLi {
  margin-top: 10px;
  margin-left: 10px;
  padding: 0 20px;
  height: 30px;
  background-color: rgba(0, 132, 255, 0.4);
  border-radius: 15px;
  line-height: 30px;
  font-size: 14px;
  color: #0084ff;
  cursor: pointer;
}
</style>
